<template>
  <div>
    <div class="bg-colorWhite" style="border-radius: 20px">
      <b-row class="d-flex align-items-center justify-content-end py-1">
        <b-col md="10" class="px-3 py-1 d-flex align-items-center">
          <div>
            <img
              src="@/assets/images/icons/profile/Avatar.png"
              img-fluid
              width="110px"
              height="110px"
            />
          </div>
          <div class="ml-2">
            <h2 class="font-weight-bolder text-colorBlack">Henry Octane</h2>
          </div>
        </b-col>
        <b-col>
          <b-button
            variant="colorBlue"
            rounded
            size="sm"
            class="text-center ml-50 px-2"
            :to="{ name: 'EditProfile' }"
          >
            Edit
          </b-button>
        </b-col>
      </b-row>

      <b-row class="px-3 mt-2 d-flex justify-content-between">
        <b-col
          md=""
          class="py-1 px-1"
          style="background-color: #faf9f6; border-radius: 20px"
        >
          <b-row>
            <b-col md="6" class="font-weight-bolder text-colorGray">Role</b-col>
            <b-col md="6" class="font-weight-bolder text-colorBlack"
              >Student</b-col
            >
          </b-row>
          <b-row class="py-1">
            <b-col md="6" class="font-weight-bolder text-colorGray"
              >Email</b-col
            >
            <b-col md="6" class="font-weight-bolder text-colorBlack"
              >student56@gamil.com</b-col
            >
          </b-row>
          <b-row>
            <b-col md="6" class="font-weight-bolder text-colorGray"
              >Contact</b-col
            >
            <b-col md="6" class="font-weight-bolder text-colorBlack"
              >923453333333</b-col
            >
          </b-row>
          <b-row class="py-1">
            <b-col md="6" class="font-weight-bolder text-colorGray"
              >Country</b-col
            >
            <b-col md="6" class="font-weight-bolder text-colorBlack"
              >United kingdom</b-col
            >
          </b-row>
          <b-row class="pb-1">
            <b-col md="6" class="font-weight-bolder text-colorGray"
              >Progress</b-col
            >
            <b-col md="6" class="font-weight-bolder text-colorBlack"
              >Completed</b-col
            >
          </b-row>
        </b-col>
        <b-col
          md=""
          class="py-1 px-1 ml-0 ml-md-50"
          style="background-color: #faf9f6; border-radius: 20px"
        >
          <b-row>
            <b-col md="6" class="font-weight-bolder text-colorGray"
              >Institution name</b-col
            >
            <b-col md="6" class="font-weight-bolder text-colorBlack"
              >Debrecen</b-col
            >
          </b-row>
          <b-row class="py-1">
            <b-col md="6" class="font-weight-bolder text-colorGray"
              >Department</b-col
            >
            <b-col md="6" class="font-weight-bolder text-colorBlack"
              >IT Software</b-col
            >
          </b-row>
          <b-row>
            <b-col md="6" class="font-weight-bolder text-colorGray"
              >Class / Course</b-col
            >
            <b-col md="6" class="font-weight-bolder text-colorBlack">10A</b-col>
          </b-row>
          <b-row class="py-1">
            <b-col md="6" class="font-weight-bolder text-colorGray"
              >Start Date</b-col
            >
            <b-col md="6" class="font-weight-bolder text-colorBlack"
              >4 May 2023</b-col
            >
          </b-row>
          <b-row class="pb-1">
            <b-col md="6" class="font-weight-bolder text-colorGray"
              >Expected End Date</b-col
            >
            <b-col md="6" class="font-weight-bolder text-colorBlack"
              >03 Feb 2024</b-col
            >
          </b-row>
        </b-col>
      </b-row>
      <b-row class="py-3 px-2">
        <b-col md="12">
          <h4 class="font-weight-bolder text-colorBlack">Your Bio</h4>
          <p class="px-4 py-2 font-weight-bold text-colorBlack">
            Optimizing mobile UI for indoor contexts involves clear navigation,
            WiFi-based services, and visuals suited for dim lighting. For
            outdoor scenarios, prioritize GPS integration, responsive design,
            and visibility in bright sunlight. Emphasize quick access, weather
            considerations, offline functionality, and larger touch targets for
            outdoor usability. These strategies collectively enhance the user
            experience across diverse environments.Optimizing mobile UI for
            indoor contexts involves clear navigation, WiFi-based services, and
            visuals suited for dim lighting. For outdoor scenarios, prioritize
            GPS integration, responsive design, and visibility in bright
            sunlight. Emphasize quick access, weather considerations, offline
            functionality, and larger touch targets for outdoor usability. These
            strategies collectively enhance the user experience across diverse
            environments.
          </p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";

import util from "@/util.js";

export default {
  components: {
    VueSelectPaginated,
  },
  data() {
    return {};
  },
  mixins: [util],
  methods: {
    async onModalClosed() {},
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getLoggedInUser",
    }),
  },
};
</script>

<style></style>
